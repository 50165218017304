<template>
  <div class="set">
    <van-cell-group>
      <van-cell
        :title="$t('语言')"
        :value="language"
        @click="languageShow = true"
      />
      <van-cell
        :title="$t('修改密码')"
        @click="$router.push('/passwordEdit')"
        is-link
      />
    </van-cell-group>
    <van-action-sheet
      v-model="languageShow"
      :actions="columns"
      @select="languageConfirm"
    />
  </div>
</template>

<script>
export default {
  name: "Set",
  data() {
    return {
      language: "",
      columns: [
        { name: "简体中文", value: "zh-cn" },
        { name: "English", value: "en-us" },
        { name: "ViệtName", value: "vi" },
      ],
      languageShow: false,
    };
  },
  mounted() {
    this.language = this.columns.find(
      (item) => item.value == this.$i18n.locale
    ).name;
  },
  methods: {
    languageConfirm(item) {
      // 语言确认事件
      this.language = this.columns.find((res) => res.value == item.value).name;
      this.languageShow = false;
      localStorage.setItem("lang", item.value);
      this.$http
        .post("/v1/getLang", {
          lang: item.value,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$i18n.setLocaleMessage(item.value, JSON.parse(res.data));
            this.$i18n.locale = item.value;
          }
        });
    },
  },
};
</script>

<style scoped>
.set {
  height: 100vh;
  width: 100vw;
  background: #f5f6fa;
}
</style>